//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import { gmatLiveClassNavIcon, liveClassNavIcon, onDemandNavIcon } from '~/static/icons/index';
import analyticEvents from '~/services/analyticEvents';
import { isFeatureAllowed } from '~/services/utilityFunctions';
import { PERMISSIONS, PLATFORM_FEATURES } from '~/static/constants.js';
import { ROUTES } from '~/constants/pageRoutes';

const COURSES = {
    LIVE_CLASS: 1,
    ON_DEMAND: 2
};

export default {
    name: 'PrepCourseSelector',
    data() {
        return {
            // Images
            liveClassNavIcon,
            onDemandNavIcon,

            // Constants
            PERMISSIONS,
            analyticEvents,

            // Data
            COURSES,
            NAV_ITEMS: []
        };
    },
    computed: {
        ...mapGetters({
            isLiveClassUpgradeAvailable: 'auth/isLiveClassUpgradeAvailable',
            isClassesAccessible: 'auth/isClassesAccessible',
            isLearningAidAccessible: 'auth/isLearningAidAccessible',
            featurePermissions: 'auth/featurePermissions',
            isAuthenticated: 'auth/isAuthenticated'
        }),
        liveClassPermission() {
            return isFeatureAllowed(PERMISSIONS.GRE_LIVE_CLASS_ACCESS, this.getUserPremiumFeatures);
        },
        recordedClassPermission() {
            return isFeatureAllowed(
                PERMISSIONS.GRE_RECORDED_CLASS_ACCESS,
                this.getUserPremiumFeatures
            );
        },
        allowedNavItems() {
            if (!this.isAuthenticated) return this.NAV_ITEMS;
            return this.NAV_ITEMS.filter((item) =>
                isFeatureAllowed(item.key, this.featurePermissions)
            );
        }
    },
    mounted() {
        const liveClassTab = {
            id: COURSES.LIVE_CLASS,
            title: 'Live Online GRE Course',
            subtitle: 'Live GRE Classes and ace the exam',
            icon: liveClassNavIcon,
            redirection: ROUTES.CLASSES_LP,
            eventFor: 'liveClass',
            key: PLATFORM_FEATURES.LIVE_CLASS_LANDING_PAGE
        };

        const onDemandTab = {
            id: COURSES.ON_DEMAND,
            title: 'On-Demand GRE Course',
            subtitle: 'GRE Course Videos with Practice & Live Doubt Sessions',
            icon: onDemandNavIcon,
            redirection: ROUTES.ON_DEMAND,
            eventFor: 'onDemand',
            key: PLATFORM_FEATURES.ON_DEMAND_LANDING_PAGE
        };

        const gmatLiveClassTab = {
            id: COURSES.GMAT_LIVE_CLASS,
            title: 'Live Online GMAT Course',
            subtitle: 'Live GMAT classes and ace the exam',
            icon: gmatLiveClassNavIcon,
            redirection: ROUTES.GMAT_BOOK_DEMO,
            eventFor: 'gmatLiveClass',
            key: PLATFORM_FEATURES.GMAT_LIVE_CLASS_LANDING_PAGE,
            isExternal: true,
            isNewFeature: true
        };

        this.NAV_ITEMS = [liveClassTab, onDemandTab, gmatLiveClassTab];
    },
    methods: {
        redirect(item) {
            if (item.isExternal) {
                window.open(item.redirection, '_blank');
            } else {
                this.$router.push(item.redirection);
            }

            if (item.eventFor) {
                this.$emit('fireEvent', item.eventFor);
            }
        }
    }
};
