//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import { PLATFORM_FEATURES } from '~/static/constants.js';
import { guideIcon, blogIcon } from '~/static/icons/index';
import { isFeatureAllowed } from '~/services/utilityFunctions';
import { EXAM_TYPES } from '~/constants/onboardingFields';

const RESOURCE_TYPE = {
    GUIDES: 1,
    BLOGS: 2
};

export default {
    name: 'ResourceSelector',
    props: {
        windowWidth: { type: Number, default: 0 }
    },
    data() {
        return {
            // Data
            RESOURCE_TYPE,
            EXAM_TYPES,
            activeTab: RESOURCE_TYPE.GUIDES,
            sections: [
                {
                    id: RESOURCE_TYPE.GUIDES,
                    title: 'Guides',
                    subtitle: 'Assesses skills for business school',
                    icon: guideIcon,
                    key: PLATFORM_FEATURES.GUIDES
                },
                {
                    id: RESOURCE_TYPE.BLOGS,
                    title: 'Blogs',
                    subtitle: 'Evaluates skills for graduate programs',
                    icon: blogIcon,
                    key: PLATFORM_FEATURES.BLOGS
                }
            ],
            showList: true
        };
    },
    computed: {
        ...mapGetters({
            featurePermissions: 'auth/featurePermissions',
            isAuthenticated: 'auth/isAuthenticated'
        }),
        allowedSections() {
            if (!this.isAuthenticated) return this.sections;
            return this.sections.filter((item) =>
                isFeatureAllowed(item.key, this.featurePermissions)
            );
        }
    },

    methods: {
        emitEvent(event, payload = null) {
            event = event === RESOURCE_TYPE.GUIDES ? 'guides' : 'blogs';
            const payloadObjToEmit = {
                eventFor: event
            };

            if (payload) {
                payloadObjToEmit.resource = payload;
            }

            this.$emit('fireEvent', payloadObjToEmit);
        },

        handleOnMouseOver(value) {
            this.activeTab = value;
            if ([RESOURCE_TYPE.GUIDES, RESOURCE_TYPE.BLOGS].includes(value)) {
                this.showList = true;
            } else {
                this.showList = false;
            }
        },
        redirectUrl(item) {
            if (this.activeTab === RESOURCE_TYPE.BLOGS) return `/${item}/blogs`;
            return `/${item}`;
        }
    }
};
