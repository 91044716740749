var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex box-border bg-white min-w-[546px] rounded-xl overflow-hidden"},[_c('div',{staticClass:"flex flex-col py-6",class:("" + (_vm.showList ? 'min-w-[383px] border-r ' : 'w-full'))},_vm._l((_vm.allowedSections),function(item,index){return _c('div',{key:("resource_item-" + index),staticClass:"group relative flex items-center space-x-5 py-3 px-8 border-l-4 border-l-transparent hover:cursor-pointer hover:bg-neutral-50 border-y border-y-transparent hover:border-y-neutral-200",class:{
                'hover:border-l-[#6D28D9] hover:text-theme-primary':
                    item.id === _vm.RESOURCE_TYPE.GUIDES && _vm.activeTab !== item.id,
                'hover:border-l-[#00838A] hover:text-[#00838A]':
                    item.id === _vm.RESOURCE_TYPE.BLOGS && _vm.activeTab !== item.id,
                'border-l-[#6D28D9] text-theme-primary bg-neutral-50 border-y-neutral-200 border-r-0':
                    item.id === _vm.RESOURCE_TYPE.GUIDES && _vm.activeTab === item.id,
                'border-l-[#00838A] text-[#00838A] bg-neutral-50 border-y-neutral-200 ':
                    item.id === _vm.RESOURCE_TYPE.BLOGS && _vm.activeTab === item.id
            },on:{"mouseover":function($event){return _vm.handleOnMouseOver(item.id)}}},[_c('img',{staticClass:"h-10 w-10",attrs:{"src":item.icon,"height":"40px","width":"40px"}}),_vm._v(" "),_c('div',{staticClass:"flex flex-col box-border w-full text-sm space-y-[3px]"},[_c('p',{staticClass:"flex items-start"},[_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(item.title))])]),_vm._v(" "),_c('p',{staticClass:"text-theme-gray-dark"},[_vm._v(_vm._s(item.subtitle))])]),_vm._v(" "),(_vm.showList)?_c('div',{staticClass:"absolute -right-[1px] w-0.5 top-0 h-full bg-neutral-50",class:item.id === _vm.activeTab ? 'block' : 'hidden'}):_vm._e()])}),0),_vm._v(" "),(_vm.showList)?_c('div',{staticClass:"py-5 px-3 rounded-tr-xl",class:("" + (_vm.showList ? 'w-1/3 bg-neutral-50' : ''))},[_c('ul',{staticClass:"rounded-lg"},_vm._l((Object.keys(_vm.EXAM_TYPES)),function(item,index){return _c('li',{key:index,staticClass:"flex text-sm font-medium hover:font-bold",on:{"click":function($event){_vm.showDropDown = false}}},[_c('NuxtLink',{staticClass:"py-2 px-3",attrs:{"to":_vm.redirectUrl(item.toLowerCase())},nativeOn:{"click":function($event){_vm.emitEvent(_vm.activeTab, item.toUpperCase())}}},[_vm._v("\n                    "+_vm._s(item)+"\n                ")])],1)}),0)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }