import { mapGetters } from 'vuex';

import { EXAM_TYPES } from '~/constants/generalConstants';
import analyticEvents from '~/services/analyticEvents';

export default {
    computed: {
        ...mapGetters({
            completeTestState: 'testSets/completeState',
            testType: 'auth/testType'
        })
    },
    methods: {
        async takeGreDiagnosticTest(source) {
            this.$store.commit('auth/setTestType', this.testType || EXAM_TYPES.GRE);
            if (!this.completeTestState.quickDiagnosticData) {
                // If the QD data is not available, try to fetch again and contiue, if it still fails then return
                const response = await this.$store.dispatch('testSets/fetchDiagnosticTestData', {
                    diagnosticTestType: 'QD',
                    testType: this.testType
                });

                if (!response.state) return;
            }

            // Set the instructions for the test to begin that is QD
            this.$store.commit(
                'testSets/setActiveTest',
                this.completeTestState.quickDiagnosticData
            );
            this.$store.commit('testSets/setShowInstructions', true);

            const trackingEvent = {
                event: analyticEvents.gre_diagnostic_test,
                data: {
                    source
                }
            };

            this.$store.dispatch('analytics/trackEvent', trackingEvent);
        }
    }
};
